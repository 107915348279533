<!--
 * @Author: huadan
 * @Date: 2021-04-13 16:37:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-13 10:15:09
 * @Description: 待办事项
-->

<style lang="less" scoped>
.schedule {
  display: flex;
  flex-direction: column;

  .title {
    font-size: @font_size_6;
    font-weight: bold;
    margin: 30px 0 20px 20px;
  }

  .schedule-table {
    margin: 0 20px;
  }

  .filter-button {
    width: 88px;
    height: 32px;
    text-align: center;
  }
}
</style>
<style lang="less">
.schedule {
  .ant-table-thead > tr > th .anticon-filter,
  .ant-table-thead > tr > th .ant-table-filter-icon {
    right: auto;
  }
}
</style>

<template>
  <NeoPageLayout class="schedule">
    <a-row class="title">
      <a-col :span="8">{{ $t('home.todo') }}</a-col>
    </a-row>
    <div class="schedule-table">
      <!-- 待办事项表格过滤 -->
      <a-radio-group v-model="todoTag">
        <a-radio-button class="filter-button" value="">
          {{ $t('schedule.all') }}
        </a-radio-button>
        <a-radio-button class="filter-button" value="1">
          {{ $t('schedule.urgent') }}
          <NeoIcon class="error" name="fire" :size="12" />
        </a-radio-button>
        <a-radio-button class="filter-button" value="0">
          {{ $t('schedule.warning') }}
          <NeoIcon class="warning" name="fire" :size="12" />
        </a-radio-button>
      </a-radio-group>
      <!-- 待办事项表格 -->
      <a-table
        class="mt-2 striped no-border"
        size="middle"
        rowKey="id"
        :columns="tableColumns"
        :data-source="dataSourceComputed"
        :pagination="false"
        :loading="loading"
        @change="handleTableChange"
      >
        <!-- <a-table-column key="index" align="center" :width="80" :title="$t('home.table_index')"> -->
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 + (current - 1) * 10 }}
        </template>
        <!-- </a-table-column> -->

        <!-- <a-table-column key="orderName" :ellipsis="true" :title="$t('home.table_order')"> -->
        <template slot="orderName" slot-scope="text, row">
          <NeoIcon v-if="row.tag === 1" class="error" name="fire" :size="18" />
          <NeoIcon v-else-if="row.tag === 0" class="warning" name="fire" :size="18" />
          <span class="pl-2" style="cursor: pointer" @click="handleJump(row)">{{
            handledOrderObj(row).name | defined
          }}</span>
        </template>
        <!-- </a-table-column> -->

        <!-- <a-table-column key="tag" align="center" :width="160" :title="$t('home.table_status')"> -->
        <template slot="tag" slot-scope="tag">
          <NeoTag :type="localeDict.warningTagType[tag] || 'primary'">{{
            localeDict.warningTagDict[tag] || localeDict.warningTagDict[null]
          }}</NeoTag>
        </template>
        <!-- </a-table-column> -->

        <!-- <a-table-column key="updateTime" :ellipsis="true" width="20%" :title="$t('home.table_update_time')"> -->
        <template slot="updateTime" slot-scope="updateTime, row">
          <span>{{ handledOrderObj(row).time | defined }}</span>
        </template>
        <!-- </a-table-column> -->
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="mt-3 fr"
        show-size-changer
        v-model="current"
        :total="total"
        :page-size="pageSize"
        @change="onChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </NeoPageLayout>
</template>

<script>
import NeoTag from '@/components/common/NeoTag'
import withSchedule from '@/mixins/withSchedule'

export default {
  name: 'schedule',
  mixins: [withSchedule],
  // eslint-disable-next-line vue/no-unused-components
  components: { NeoTag },
  data() {
    return {
      localeDict: window.$g.localeDict,
      //待办事项表格数据
      dataSource: [],
      dataSourceInitial: [],
      columns: [
        {
          title: this.$t('home.table_index'),
          key: 'index',
          dataIndex: 'index',
          align: 'center',
          width: 80,
          scopedSlots: { customRender: 'index' },
        },
        {
          title: this.$t('home.table_order'),
          key: 'orderName',
          dataIndex: 'orderName',
          ellipsis: true,
          scopedSlots: { customRender: 'orderName' },
        },
        {
          title: this.$t('home.table_status'),
          key: 'tag',
          dataIndex: 'tag',
          align: 'center',
          width: 160,
          scopedSlots: { customRender: 'tag' },
        },
        {
          title: this.$t('home.table_update_time'),
          key: 'updateTime',
          dataIndex: 'updateTime',
          width: '20%',
          ellipsis: true,
          scopedSlots: { customRender: 'updateTime' },
        },
      ],
      //待办事项表格每页显示大小
      pageSize: 10,
      //待办事项表格当前页
      current: 1,
      //待办事项表格总数
      total: 50,
      loading: false,
      //待办事项表格过滤值
      todoTag: '',
      tableFilterParam: null,
      comsFilters: [],
    }
  },
  computed: {
    dataSourceComputed() {
      return this.dataSource.slice((this.current - 1) * this.pageSize, this.current * this.pageSize)
    },
    tableColumns() {
      // 如果是客户端，增加一列企业实体数据
      if (this.$g.isCust) {
        const start = [...this.columns.slice(0, 3)]
        const end = [...this.columns.slice(3)]
        const entity = {
          title: this.$t('schedule.entity'),
          key: 'supTenantName',
          dataIndex: 'supTenantName',
          width: 300,
          filters: this.comsFilters,
          filterMultiple: true,
          customRender: (text) => {
            return {
              children: text ?? '---',
            }
          },
        }

        return [...start, entity, ...end]
      } else {
        return this.columns
      }
    },
  },

  watch: {
    //根据所选状态筛选表格数据
    todoTag(val) {
      this.todoTag = val
      this.current = 1
      this.fetch()
    },
  },

  mounted() {
    this.fetch()
    this.$g.isCust && this.getAgencyListInfo()
  },

  methods: {
    async getAgencyListInfo() {
      try {
        const data = await this.$http('getAgencyListInfo', {})
        const coms = data.map((item) => {
          return {
            text: item.name,
            value: item.supTenantCode,
          }
        })
        this.comsFilters = coms
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    getFilteredTableData() {
      if (!this.$g.isEmpty(this.tableFilterParam)) {
        const result = Object.values(this.dataSourceInitial).filter((item) => {
          return this.tableFilterParam.some((_item) => _item.includes(item?.supTenantCode))
        })
        // console.log(`result`, result)
        return result
      } else {
        return this.dataSourceInitial
      }
    },
    // 手动过滤表格
    handleTableChange(pagination, filters) {
      const { supTenantName: arr } = filters
      this.tableFilterParam = arr
      if (arr.length !== 0) {
        // 过滤
        const dataSource = this.getFilteredTableData()
        this.dataSource = dataSource
        this.total = dataSource.length
      } else {
        // 重置
        this.dataSource = this.dataSourceInitial
        this.total = this.dataSourceInitial.length
        this.tableFilterParam = null
        this.pageSize = 10
      }
      this.current = 1
    },
    //表格更新数据
    async fetch() {
      try {
        this.loading = true
        const data = await this.$http('stoneTodoPage', {
          todoTag: this.todoTag,
        })
        this.loading = false
        // 判断一下是否有过滤条件
        this.dataSourceInitial = data
        this.dataSource = this.getFilteredTableData()
        this.total = this.dataSource.length
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    //页码改变的回调，参数是改变后的页码及每页条数
    onChange(page, pageSize) {
      this.current = page
      this.pageSize = pageSize
    },

    //pageSize 变化的回调
    onShowSizeChange(current, size) {
      this.current = current
      this.pageSize = size
    },
  },
}
</script>
