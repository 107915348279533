/*
 * @Author: wjj
 * @Date: 2021-05-18 16:26:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-07 17:30:44
 * @Description: part
 */
import moment from 'moment'
export default {
  methods: {
    handledOrderObj(params) {
      let { orderCount, orderName, status, updateTime, todoType } = params
      const obj = {}
      if (todoType == 1) {
        switch (Number(status)) {
          //待确认终稿
          case 5:
            obj.name = orderName + this.$t('home.order_name_rule5')
            obj.time = this.isToday(updateTime) + this.$t('home.end')
            break
          case 7:
            obj.name = orderName + this.$t('home.order_name_rule5')
            obj.time = this.isToday(updateTime) + this.$t('home.end')
            break
          case 2: //待确认报价
            obj.name = orderName + this.$t('home.order_name_rule2')
            obj.time = this.isToday(updateTime) + this.$t('home.place_order')
            break
          case 9: //待下单方确认结算
            obj.name = orderCount + this.$t('home.order_name_rule3')
            obj.time = this.$t('home.updated_on') + this.isToday(updateTime)
            break
          case 11: //供应商驳回
            obj.name = orderCount + this.$t('home.order_name_rule4')
            obj.time = this.$t('home.updated_on') + this.isToday(updateTime)
            break
          case 4: // 待上传终稿
            obj.name = orderName + this.$t('home.order_name_rule1')
            obj.time = this.isToday(updateTime) + this.$t('home.end')
            break
          case 1: //等待报价
            obj.name = orderName + this.$t('home.order_name_rule6')
            obj.time = this.isToday(updateTime) + this.$t('home.place_order')
            break
          case 6: //待重新提交终稿
            obj.name = orderName + this.$t('home.order_name_rule7')
            obj.time = this.isToday(updateTime) + this.$t('home.end')
            break
          case 10: //待供应商确认结算
            obj.name = orderCount + this.$t('home.order_name_rule8')
            obj.time = this.$t('home.updated_on') + this.isToday(updateTime)
            break

          default:
            ;(obj.name = orderName), (obj.time = this.isToday(updateTime))
            break
        }
      } else if (todoType == 2) {
        //待回复问题
        obj.name = orderName + this.$t('home.order_name_rule9')
        obj.time = this.$t('home.updated_on') + this.isToday(updateTime)
      }
      return obj
    },

    isToday(time) {
      const today = moment().format(this.$t('dateFormat.c')).split(' ')[0]
      const date = moment(time).format(this.$t('dateFormat.c')).split(' ')[0]
      if (today === date) {
        return moment(time).calendar()
      } else {
        return moment(time).format(this.$t('dateFormat.c'))
      }
    },
    handleJump(params) {
      console.log(`params`, params)
      console.log('route', this.$g.route)
      let { status, orderId: id, produceVersion = 1, jobId = null, appId = null, cusAgencyName, todoType } = params
      let url = ''

      status = Number(status)

      const uploadFinalDraft = () => {
        if (params?.jobId) {
          // 新开页面跳转Neotrans的上传页面
          if (produceVersion == 1) {
            url = window.SITE_CONFIG['neotransUrl'][0] + `?jobId=${jobId}&appId=${appId}`
            window.open(url)
          } else {
            url = window.SITE_CONFIG['neotransUrl'][1] + `?id=${jobId}&status=${status}`
            window.open(url)
          }
        } else {
          this.$router.push({
            path: this.$g.route.order_orderInfo,
            query: { id },
          })
        }
      }

      if (todoType == 1) {
        switch (status) {
          case 9: //待下单方确认结算
            // obj.name = orderCount + this.$t('个订单终稿验收通过，待您确认结算金额')
            // 跳转至结算-待确认结算
            // this.$router.push({ path: '/payment', query: { tabVal: '1' } })
            this.$router.push({
              path: this.$g.route.payment,
              query: { tabVal: '1' },
            })
            break
          case 10: //待供应商确认结算
            // obj.name = orderCount + this.$t('个订单终稿验收通过，待您确认结算金额')
            // 跳转至结算-待确认结算
            this.$router.push({
              path: this.$g.route.payment,
              query: { tabVal: '2', cusAgencyName },
            })
            break
          case 11: //供应商驳回
            // obj.name = orderCount + this.$t('个订单结算金额被供应商驳回，待您修改')
            // 跳转至结算-供应商驳回
            this.$router.push({
              path: this.$g.route.payment,
              query: { tabVal: '3' },
            })
            break

          case 1: //等待报价
            // obj.name = orderName + this.$t('待您创建报价')
            // 跳转至xxxx订单的订单详情页
            this.$router.push({
              path: this.$g.route.order_orderInfo,
              query: { id },
            })
            break

          //待确认终稿
          case 5:
            // obj.name = orderName + '待您确认终稿'
            // 跳转xxxx订单的下载终稿弹窗页
            this.$router.push({
              path: `${this.$g.route.order_orderInfo}?id=${id}&action=1`,
            })
            break
          //待确认终稿
          case 7:
            // obj.name = orderName + '待您确认终稿'
            // 跳转xxxx订单的下载终稿弹窗页
            this.$router.push({
              path: `${this.$g.route.order_orderInfo}?id=${id}&action=1`,
            })
            break
          case 2: //待确认报价
            // obj.name = orderName + this.$t('待您确认报价单')
            // 跳转至xxxx订单的确认报价单页
            this.$router.push({
              path: `${this.$g.route.order_orderInfo}?id=${id}&action=2`,
            })
            break

          case 4: //    待上传终稿
            uploadFinalDraft()
            break
          case 6: //    待重新提交终稿
            uploadFinalDraft()
            break
          default:
            console.error('意外')
            break
        }
      } else if (todoType == 2) {
        //待回复问题跳转
        this.$router.push({
          path: `${this.$g.route.order_orderInfo}?id=${id}&action=3`,
        })
      }
    },
  },
}
